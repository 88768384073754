<template>
    <div class="" >
        <div class="fixed-top mb-1  p-0 mx-0 navigation border-bottom shadow">
            <b-navbar toggleable="lg" class="navbar navbar-expand-lg navbar-light bg-light">
                <b-navbar-brand class="lobster" href="#top">Maestro Chilaquil</b-navbar-brand>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                        <b-nav-item href="#top">Inicio</b-nav-item>
                        <b-nav-item href="#SectionPlatillos">Platillos</b-nav-item>
                        <b-nav-item href="#SectionContacto">Contáctanos</b-nav-item>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </div>
        <section id="SectionInicio" class="my-5 pt-3  ">
            <div class=" bg-white  ">
                <b-img rounded="circle" fluid src="./Projecto-chilaquil_txtwhite.png" alt="" style="width: 700px; height: 620px background-color: #ffaa3b; " class="text-center m-0 p-0 " ></b-img>
                <!-- <b-img rounded="circle" fluid src="./Projecto-chilaquil_.png" alt="" style="width: 800px; " class=" m-0 p-0 " ></b-img> -->
            <br>
            <div>
                <b-img rounded="" fluid src="./hoja2.png" alt="" style="width: 80px; height: 700px background-color: #ffaa3b; " class="text-center m-0 p-0 " ></b-img>
            </div>
            <span class="mb-2 badge badge badge-pill row">¡Sazón mexicano!</span>
            </div>
            <br>
            
            
            <!-- <h1 class="display-4 logo">  Maestro Chilaquil</h1> -->
        </section>

        <section  class=" pb-3 shadow rounded-lg bg-white " >
            <div class="pt-3" style="background-color: #ffaa3b; ">
                <div>
                    
                    <span class="font-weight-light"></span>
                </div>
                <h2 class="font-weight-light text-white lobster">-Chilaquiles de alta cocina-</h2>
                <h4 class="font-weight-normal text-white  lobster">Amantes del sabor mexicano</h4>
                <b-img rounded="" fluid src="./hoja3.png" alt="" style="width: 80px; height: 700px background-color: #ffaa3b; " class="text-center m-0 p-0 " ></b-img>
                <span class="mb-2 badge badge-success badge-pill row">Usamos empaques biodegradables</span>
                <!-- <h4 class="font-weight-normal text-dark m-2 pb-3">Estamos comprometidos con el planeta. Todos nuestros empaques son biodegradables</h4> -->
                
                <!-- <b-img rounded="circle" fluid src="./Projecto-chilaquil_.png" alt=""  class=" m-0 p-0 " ></b-img> -->
            </div>
        </section>

        <div class="m-5">
            <b >---</b>
        </div>

        <section id="SectionPlatillos" class="py-5 mt-4 bg-white shadow rounded-lg">
            <div class="text-center pt-4">
                <h3 class="my-2 mt-4 lobster bg-success text-white shadow  p-2">Nuestros platillos  </h3>
            </div>
            <div class="container pt-3 mt-3">
                <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="3500"
                controls
                indicators
                background="#ababab"
                img-width="1024"
                img-height="880"
                style="text-shadow: 1px 1px 2px #333;"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
                >
                    <b-carousel-slide
                        caption="Rojos"
                        text="Con arrachera"
                        img-src="./Platillos/RojosArrachera1.001.jpeg"
                    ></b-carousel-slide>
                    <b-carousel-slide
                        caption="Verdes"
                        text="Con arrachera"
                        img-src="./Platillos/VerdesArrachera1.001.jpeg"
                    ></b-carousel-slide>
                    <b-carousel-slide
                        caption="Rojos"
                        text="Con pollo"
                        img-src="./Platillos/RojosPollo2.001.jpeg"
                    ></b-carousel-slide>
                    <b-carousel-slide
                        caption="Verdes"
                        text="Con pollo"
                        img-src="./Platillos/VerdesPollo1.001.jpeg"
                    ></b-carousel-slide>
                    <b-carousel-slide
                        caption="Rojos"
                        text="Con pollo"
                        img-src="./Platillos/RojosPollo1.001.jpeg"
                    ></b-carousel-slide>
                </b-carousel>

            </div>
            <div>
                <p class="text-secondary">Nuestros platillos están elaborados con la tradición de generaciones de familias mexicanas. </p>
                <p class="text-secondary">Todos nuestros ingredientes son seleccionados con mucho cuidado y son de la más alta calidad.</p>
            </div>
        </section>



        <div class="m-5">
            <b >---</b>
        </div>

        <section id="SectionContacto" class="py-5 my-5 shadow bg-white rounded-lg">
            <div class="text-center">
                <h3 class="my-2 mt-4 lobster bg-success text-white shadow p-2">Contactanos  </h3>
            </div>
            <div class="container py-5">
                <div class="row mt-5">
                    <div class="col-lg-12">
                        <div class="text-center">
                            <div class="py-5">
                                <p class="h5">Pedidos por Whatsapp y telegram</p>
                                <a  class=" h4 text-dark " href="tel:5527685767">5527685767</a>
                                <br>
                                <button type="button" onclick="window.location.href='https://t.me/MaestroChilaquil'" class="btn  btn-sm mt-2 ml-1 text-white" style="background: #0088CC;"><i class="uil uil-instagram"></i><b>Telegram</b> </button>
                                <button type="button" onclick="window.location.href='https://wa.me/5215527685767'" class="btn  btn-sm mt-2 ml-1 text-white" style="background: #00BB2D;"><i class="uil uil-facebook-f"></i><b >Whatsapp</b> </button>
                            </div>
                            <br>
                            <p class="h5">Encuentranos en redes sociales </p>
    
                            <button type="button" onclick="window.location.href='https://www.instagram.com/maestrochilaquil'" class="btn  btn-sm mt-2 ml-1 text-white" style="background: linear-gradient(to right, #fd5949,#d6249f);"><i class="uil uil-instagram"></i> Instagram</button>
                            <button type="button" onclick="window.location.href='https://www.facebook.com/Maestro-Chilaquil-101065908737441/'" class="btn  btn-sm mt-2 ml-1 text-white" style="background: #2A44C4;"><i class="uil uil-facebook-f"></i>Facebook</button>
                            <br/>    
                            
                        </div>
                    </div>
                </div>
            </div> <!-- end container-->
        </section>
     





    </div>
</template>

<script>



export default {
    name:'iniciocomp',
    components: {  },
    data(){
        return{
        }
    },
    
    computed:{
    },
    methods: {

    }
}
</script>