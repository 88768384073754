<template>
    <div id="app" style="  top: 0; left: 0; " >
      
        <main   id="page-wrap" class=" " >
        <InicioComp> </InicioComp>   
        </main> 
        <footer class=" py-2">
            <hr/>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="mt-5">
                            <p class="text-50 mt-4 text-secondary text-center mb-0">© 2021 Maestro Chilaquil<i class="uil uil-registered"></i></p>

                        </div>
                    </div>
                </div>
            </div>
        </footer>
        




    </div>
</template>

<script>
// @ is an alias to /src
import InicioComp from '@/components/InicioComp.vue'

export default {
  name: 'app',
  components: {
    InicioComp
  },
  methods:{
    anterior(){
      this.$router.go(-1)
    },
    siguiente(){
      this.$router.go(1)
    }, 
  },
}
</script>
